<template>
  <el-menu
    :collapse="isOpen"
    background-color="#fff"
    text-color="#000"
    active-text-color="#646cff"
    router
    mode="vertical"
    :collapse-transition="false"
    :unique-opened="true"
  >
    <el-menu-item @click="removeRouteHandle" index="/home">
      <i class="el-icon-s-home"></i>
      <span slot="title">首页</span>
    </el-menu-item>
    <el-submenu
      :index="item.id + ''"
      v-for="(item, index) in menuList"
      :key="index"
    >
      <template slot="title">
        <i :class="item.icon" class="subitem-left-icon"></i>
        <span slot="name">{{ item.menuName }}</span>
      </template>
      <el-menu-item-group
        v-for="(subItem, index) in item.children"
        :key="index"
      >
        <el-menu-item
          :index="'' + item.path + '' + subItem.path"
          @click="getRouteInfoHandle(item, subItem)"
          >{{ subItem.menuName }}</el-menu-item
        >
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<script>
import { listMenuByRole } from '@/api/login'
import { mapState, mapMutations } from 'vuex'
export default {
  data () {
    return {
      menuList: []
    }
  },
  computed: {
    ...mapState('header', {
      isOpen: state => state.isOpen
    })
  },
  created () {
    listMenuByRole({}).then(res => {
      const menuList = res.data.sort((a, b) => {
        return a.sortNum - b.sortNum
      })
      this.menuList = menuList
    })
  },
  methods: {
    ...mapMutations('activeRouter', ['setActiveRouter']),
    /* 获取当前路由信息 */
    getRouteInfoHandle (parentRouteInfo, childrenRouteInfo) {
      const activeRouter = [
        {
          name: parentRouteInfo.menuName,
          path: `/${parentRouteInfo.path}/${parentRouteInfo.children[0].path}`
        },
        {
          name: childrenRouteInfo.menuName
        }
      ]
      this.setActiveRouter(activeRouter)
      window.sessionStorage.setItem(
        'activeRouter',
        JSON.stringify(activeRouter)
      )
    },
    /* 清空当前路由信息 */
    removeRouteHandle () {
      this.setActiveRouter([])
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu {
  .el-menu-item-group {
    ::v-deep .el-menu-item-group__title {
      padding: 0;
    }
  }
  .el-menu-item:hover {
    background-color: #F1F0FF !important;
  }
  .subitem-left-icon {
    margin-right: 5px;
    min-width: 24px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
  }
}
</style>
